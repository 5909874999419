import { Spinner } from '@nextui-org/react';
import styles from './mainLoading.module.css'; // Import SCSS module

const MainLoading = () => {
    return (
        <section
            className={`${styles.container} ${styles.hidden}`}
        >
            <Spinner size="xl" color="primary" label="Loading..." />
        </section>
    );
};

export default MainLoading;
