"use client";

import { useState, useEffect } from "react";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Input,
} from "@nextui-org/react";
import { usePathname } from "next/navigation";
import styles from "./ageVerificationPopup.module.css"; // Import SCSS module

const AgeVerificationPopup = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const pathname = usePathname();

    const [age, setAge] = useState("");

    useEffect(() => {
        const cookies = document.cookie.split("; ");
        const ageVerified = cookies.find((cookie) =>
            cookie.startsWith("ageVerified=")
        );
        if (!ageVerified) {
            onOpen();
        }
    }, [onOpen, pathname]);

    const handleVerify = (e) => {
        e.preventDefault();
        if (parseInt(age) >= 18) {
            document.cookie = "ageVerified=true; max-age=" + 24 * 60 * 60; // Cookie expires in 1 day (24 hours)
            onClose();
        }
    };

    return (
        <Modal
            backdrop="blur"
            isOpen={isOpen}
            onOpenChange={onOpen}
            isDismissable={false}
            hideCloseButton
            isKeyboardDismissDisabled={true}
        >
            <ModalContent as={"form"} onSubmit={handleVerify}>
                <ModalHeader className={styles.header}>Age Verification</ModalHeader>
                <ModalBody>
                    <p>Please enter your age to verify you are over 18:</p>
                    <Input
                        type="number"
                        label="Enter Your Age"
                        fullWidth
                        value={age}
                        min={18}
                        max={99}
                        onValueChange={setAge}
                        validationBehavior="native"
                        isRequired
                        autoFocus={isOpen}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit" className={styles.button}>
                        Verify
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AgeVerificationPopup;
