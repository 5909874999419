import Link from "next/link";
import styles from "./navbarMenu.module.css"; // Import SCSS module

const NavbarMenu = () => {
    return (
        <>
            <Link href="/" className={styles.link}>Home</Link>
            <Link href="/trending" className={styles.link}>Trending</Link>
            <Link href="/categories" className={styles.link}>Categories</Link>
            <Link href="/about" className={styles.link}>About</Link>
            <Link href="/contact" className={styles.link}>Contact</Link>
        </>
    )
}
export default NavbarMenu;