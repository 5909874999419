"use client"
import Link from 'next/link';
import { BackIcon, MenuIcon, SearchIcon } from '@/components/icons';
import { usePathname } from 'next/navigation';
import { useNavbarAndSidebarToggle } from '@/app/context/SidebarAndNavbarToggle';
import styles from './navBar.module.css'; // Import SCSS module
import Image from 'next/image';
import NavbarMenu from '../navbarMenu/navbarMenu';


const NavBar = () => {
    const pathname = usePathname();
    const { searchQuery, setSearchQuery } = useNavbarAndSidebarToggle();

    return (
        <header className={styles.header}>
            <nav className={styles.nav}>
                {!pathname?.startsWith("/search") ?
                    <Link href="/" className={styles.logo}>
                        <Image priority src="/uncoveredvibes-logo-original.png" width={250} height={100} className={styles.logoImg} alt='Uncovered Vibes Logo' />
                    </Link>
                    :
                    <>
                        <Link href="/" className={`${styles.logoSm} ${styles.logo}`}>
                            <Image priority src="/uncoveredvibes-logo-original.png" width={250} height={100} className={styles.logoImg} alt='Uncovered Vibes Logo' />
                        </Link>
                        <Link className={styles.backIconContainer} href="/">
                            <BackIcon className={styles.backIcon} />
                        </Link>
                    </>
                }
                <div className={styles.navbarMenuContainer}>
                    <NavbarMenu />
                </div>
                <div className={`${styles.searchBox} ${pathname?.startsWith("/search") ? styles.maxMdGrow : ''}`}>
                    {
                        !pathname?.startsWith("/search") ?
                            <>
                                <Link className={styles.searchAnchor} href="/search" aria-label='Click here to search any video'>
                                    <SearchIcon className={styles.searchIcon} />
                                </Link>
                            </>
                            :
                            <input
                                type='text'
                                autoFocus
                                placeholder="Type to search..."
                                value={searchQuery}
                                onChange={(e)=>{setSearchQuery(e.target.value)}}
                                className={styles.input}
                            />

                    }
                    <Link className={styles.menuIconContainer} href="/more">
                        <MenuIcon className={styles.menuIcon} />
                    </Link>
                </div>
            </nav>
        </header>
    );
};

export default NavBar;
