"use client"
import { Tab, Tabs } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { CategoryIcon, HomeIcon, TrendingIcon, VideoLibraryIcon } from '@/components/icons';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import styles from './bottomBar.module.css';

const BottomBar = () => {
    const pathname = usePathname();
    const [selected, setSelected] = useState("/");

    useEffect(() => {
        setSelected(pathname);
    }, [pathname]);

    return (
        <Tabs
            variant={"underlined"}
            color="default"
            classNames={{
                base: styles.base,
                tabList: styles.tabList,
                tab: styles.tab,
                tabContent: styles.tabContent,
                cursor: styles.cursor,
                panel: styles.panel,
                wrapper: styles.wrapper,
            }}
            aria-label="BottomBar tabs"
            selectedKey={selected}
            onSelectionChange={setSelected}
        >
            <Tab className="hidden" key="other" />
            <Tab
                key="/"
                title={
                    <Link href="/" className={styles.iconContainer}>
                        <HomeIcon className={styles.icon} />
                        <span className={styles.text}>Home</span>
                    </Link>
                }
            />
            <Tab
                key="/search"
                title={
                    <Link href="search" className={styles.iconContainer}>
                        <VideoLibraryIcon className={styles.icon} />
                        <span className={styles.text}>Videos</span>
                    </Link>
                }
            />
            <Tab
                key="/trending"
                title={
                    <Link href="/trending" className={styles.iconContainer}>
                        <TrendingIcon className={styles.icon} />
                        <span className={styles.text}>Trends</span>
                    </Link>
                }
            />
            <Tab
                key="/categories"
                title={
                    <Link href="/categories" className={styles.iconContainer}>
                        <CategoryIcon className={styles.icon} />
                        <span className={styles.text}>Catelog</span>
                    </Link>
                }
            />
        </Tabs>
    );
};

export default BottomBar;
